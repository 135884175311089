import { ColumnType } from '@type/config/column.type';

export const checkVisibleField = (column: ColumnType, formValue: { [key: string]: any }): boolean => {
  if (!column.visibleRule) {
    return true;
  }

  const { fieldKey, equal, oneOf, contains, notNull } = column.visibleRule;
  const fieldValue = formValue[fieldKey];

  if (equal !== undefined && equal === fieldValue) {
    return true;
  }

  if (notNull !== undefined && !!fieldValue) {
    return true;
  }

  if (contains && contains.every((conVal) => fieldValue.indexOf(conVal) !== -1)) {
    return true;
  }

  if (oneOf && oneOf.some((value) => {
    if (Array.isArray(fieldValue)) {
      return fieldValue.indexOf(value) !== -1;
    }

    return value === fieldValue;
  })) {
    return true;
  }

  return false;
}