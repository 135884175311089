import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


import { getCollectionListAction } from '@stores/collection/collection.actions';
import { ButtonCreateBlock } from '@blocks/button-create';
import { CardDataBlock } from '@blocks/card-data';
import { PaginationBlock } from '@blocks/pagination';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';

import { EntityListProps } from './entity-list.props';
import { EntityTableComponent } from './compontents/entity-table';
import { ButtonSearchBlock } from '@blocks/button-search';
import { EntitySearchComponent } from './compontents/entity-search';
import { ButtonBackBlock } from '@blocks/button-back';
import { TransitionOpacityBlock } from '@blocks/transition-opacity';


const EntityListFeature = ({ entity, parentId }: EntityListProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const collection = useAppSelector((state) => state.collection.collections[entity.key]);
  const role = useAppSelector((state) => state.auth.user!.role);

  useEffect(() => {
    dispatch(getCollectionListAction({
      query: {
        key: entity.key,
        parentKey: entity.parent,
        parentId,
        page: 1,
      },
      searchQuery: {},
    }));
  }, [dispatch, entity.key, entity.parent, parentId]);

  const getListByPage = (page: number) => {
    dispatch(getCollectionListAction({
      query: {
        key: entity.key,
        parentKey: entity.parent,
        parentId,
        page,
      },
      searchQuery: collection.searchQuery,
    }));
  }

  const getListByQuery = (query: { [key: string]: any }) => {
    setIsSearch(false);

    dispatch(getCollectionListAction({
      query: {
        key: entity.key,
        parentKey: entity.parent,
        parentId,
        page: 1,
      },
      searchQuery: query,
    }));
  }

  return (
    <CardDataBlock
      title={isSearch ? `Найти ${entity.label.plural.toLowerCase()}` : entity.label.plural}
      extra={
        <>
          {(!isSearch && role[`${entity.key}_CREATE`] !== 'NEVER') && <ButtonCreateBlock onClick={() => navigate(`${entity.key}-create`)} />}
          {isSearch && <ButtonBackBlock onClick={() => setIsSearch(false)} />}
          {!isSearch && (
            <ButtonSearchBlock
              contained={!!Object.keys(collection.searchQuery).length}
              count={Object.keys(collection.searchQuery).length}
              onClick={() => setIsSearch((prev) => !prev)}
            />
          )}
        </>
      }
      loading={collection.status === 'loading'}
      noData={(!collection.list || (Array.isArray(collection.list) && !collection.list.length)) && !isSearch}
      actions={!!collection.pages && collection.pages > 1 && !isSearch && (
        <PaginationBlock current={collection.page!} count={collection.pages} setCurrent={(page) => getListByPage(page)}/>
      )}
    >
      {isSearch && (
        <TransitionOpacityBlock>
          <EntitySearchComponent entity={entity} onSearchHandler={getListByQuery} searchQuery={collection.searchQuery} />
        </TransitionOpacityBlock>
      )}
      {!isSearch && (
        <TransitionOpacityBlock>
          <EntityTableComponent entity={entity} collection={collection} />
        </TransitionOpacityBlock>
      )}
    </CardDataBlock>
  );
}

export { EntityListFeature };
