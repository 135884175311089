
import { Badge, Button } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import { ButtonSearchProps } from './button-search.props';


const ButtonSearchBlock = ({ onClick, count, disabled, contained }: ButtonSearchProps) => {
  return (
    <Badge color="warning" badgeContent={count}>
      <Button
        color="primary"
        variant={contained ? 'contained' : 'outlined'}
        startIcon={<ManageSearchIcon />}
        onClick={onClick}
        size="large"
        type='submit'
        disabled={disabled}
      >
        Поиск
      </Button>
    </Badge>
  );
};

export { ButtonSearchBlock };
