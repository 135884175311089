import { EntityType } from '@type/config/entity.type';

export const statusZadachiEntity: EntityType = {
  key: 'status_zadachi',
  label: {
    singular: 'Статус задачи',
    plural: 'Статусы задачи',
    genitive: 'Статус задачи',
  },
  type: 'list',
  parent: 'zadacha',
  columns: [
    {
      label:'Дата',
      key: 'data',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label:'Описание состояния работ/объекта',
      key: 'opisanie-sosotoyaniya-rabot-obekta',
      type: 'long-text',
      display: {
        list: false,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label:'Ссылка на фото 1',
      key: 'ssylka-na-foto-1',
      type: 'link',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
    {
      label:'Ссылка на фото 2',
      key: 'ssylka-na-foto-2',
      type: 'link',
      display: {
        list: false,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
    {
      label:'Ссылка на фото 3',
      key: 'ssylka-na-foto-3',
      type: 'link',
      display: {
        list: false,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
    {
      label:'Требует внимания',
      key: 'trebuet-vnimaniya',
      type: 'boolean',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
  ]
}