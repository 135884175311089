import { ConfigType } from '@type/config/config.type';
import { obektEntity } from './obekt.entity';
import { statusObektaEntity } from './status-obekta.entity';
import { statusZadachiEntity } from './status-zadachi.entity';
import { zadachiEntity } from './zadacha.entity';
// import { zakazchikEntity } from './zakazchik.entity';


export const configDcs: ConfigType = {
  appTitle: 'NP39',
  entities: [
    obektEntity,
    zadachiEntity,
    statusObektaEntity,
    statusZadachiEntity,
    // zakazchikEntity
  ],
};
