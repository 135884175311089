import { EntityType } from '@type/config/entity.type';

export const obektEntity: EntityType = {
  key: 'obekt',
  label: {
    singular: 'Объект',
    plural: 'Объекты',
    genitive: 'Объект',
  },
  type: 'list',
  parent: 'root',
  columns: [
    {
      label:'Название объекта',
      key: 'nazvanie-obekta',
      type: 'long-text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
      search: true,
    },
    {
      label:'Плановая дата ввода',
      key: 'planovaya-data-vvoda',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
    {
      label:'Дата заключения контракта',
      key: 'data-zaklyucheniya-kontrakta',
      type: 'date',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
    },
    {
      label:'Стоимость контракта (тыс. руб.)',
      key: 'stoimost-kontrakta',
      type: 'decimal',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      counted: true,
    },
    {
      label:'Подрядчик',
      key: 'podryadchik',
      type: 'text',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      search: true,
      visibleRule: {
        fieldKey: 'stoimost-kontrakta',
        notNull: true,
      },
    },
    {
      label:'ИНН подрядчика',
      key: 'inn-podryadchika',
      type: 'number',
      display: {
        list: false,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      visibleRule: {
        fieldKey: 'stoimost-kontrakta',
        notNull: true,
      },
    },
    {
      label:'Период обновления в днях',
      key: 'period-obnovleniya-v-dnyah',
      type: 'number',
      display: {
        list: true,
        link: false,
        create: true,
        update: true,
        one: true,
      },
      required: true,
    },
  ]
}