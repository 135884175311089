import { Link } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { dateTranslate } from '@helpers/date-translate.helper';
import { buildColumnValue } from '@helpers/build-column-value.helper';

import { EntityTableProps } from './entity-table.props';



const EntityTableComponent = ({ entity, collection }: EntityTableProps) => {
  return (
    <div style={{overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap'}}>
      <TableContainer style={{width: '100%'}}>
        <Table aria-label="user-detail">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{entity.label.singular}</TableCell>

              {entity.columns.map((column) => {
                if (!column.display.list) {
                  return null;
                }

                return <TableCell key={column.key}>{column.label}</TableCell>
              })}
              
              <TableCell>Создал</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Обновил</TableCell>
              <TableCell>Дата обновления</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {collection.list && collection.list.map((item) => (
                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">{item.id}</TableCell>
                  <TableCell component="th" scope="row">
                    <Link to={entity.key + '-' +  item.id}>{entity.label.singular} №{item.id}</Link>
                  </TableCell>

                  {entity.columns.map((column) => {
                    if (!column.display.list) {
                      return null;
                    }

                    const value = item[column.key];

                    if (column.type === 'link') {
                      return (
                        <TableCell key={column.key}>
                          {!!value ? (<a href={value} rel="noreferrer" target="_blank">Ссылка</a>) : ''}
                        </TableCell>
                      )
                    }

                    const getColumnContent = (value: any) => {
                      return column.display.link
                        ? <Link to={entity.key + '-' +  item.id}>{value}</Link>
                        : value
                    }

                    return (
                      <TableCell key={column.key}>{getColumnContent(buildColumnValue(column, value))}</TableCell>
                    );
                  })}
                  
                  <TableCell>{item.creator.name}</TableCell>
                  <TableCell>{dateTranslate(item.createdAt)}</TableCell>
                  <TableCell>{item.updater.name}</TableCell>
                  <TableCell>{dateTranslate(item.updatedAt)}</TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export { EntityTableComponent };
