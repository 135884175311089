import { SyntheticEvent, useState } from 'react';

import { FieldTextBlock } from '@blocks/field-text';
import { ChangeFieldEventType } from '@type/shared/change-field-event.type';

import { EntitySearchProps } from './entity-search.props';
import styles from './entity-search.module.scss';
import { ButtonSearchBlock } from '@blocks/button-search';
import { ButtonClearBlock } from '@blocks/button-clear';
import { FieldSelectBlock } from '@blocks/field-select';
import { useAppSelector } from '@hooks/redux.hooks';
import { FieldSnilsBlock } from '@blocks/field-snils';


const EntitySearchComponent = ({ entity, onSearchHandler, searchQuery }: EntitySearchProps) => {
  const columnsForSearch = entity.columns.filter((column) => column.search);
  const initialFormValue: { [key: string]: any } = {
    creator: '',
  };
  
  Object.keys(searchQuery).forEach((key) => {
    const strongKey = key.split('_')[1];
    initialFormValue[strongKey] = searchQuery[key];
  });

  columnsForSearch.forEach((column) => {
    if (!initialFormValue[column.key]) {
      initialFormValue[column.key] = '';
    }
  });

  const [formValue, setFormValue] = useState(initialFormValue);
  const [formDirty, setFormDirty] = useState(false);
  const creators = useAppSelector((state) => state.user.dictionary);

  const changeFormValue = (e: ChangeFieldEventType | null): void => {
    setFormDirty(true);
    setFormValue((state) => {
      if (!e) return state;

      return {
        ...state,
        [e!.target.name]: e!.target.value,
      }
    });
  };

  const onSubmitForm = (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const query: { [key: string]: any } = {};

    if (formValue.creator) {
      query.search_creator_creator = formValue.creator;
    }

    Object.keys(formValue).forEach((key) => {
      if (formValue[key] && key !== 'creator') {
        const column = entity.columns.find((column) => column.key === key);
        query[`search_${key}_${column?.type}`] = formValue[key];
      }
    });

    onSearchHandler(query);
  }

  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <div className={styles['entity-search']}>
          <form onSubmit={(e: SyntheticEvent) => onSubmitForm(e)}>
            {columnsForSearch.map((column) => {
              if (column.type === 'select') {
                return (
                  <FieldSelectBlock
                    key={column.key}
                    label={column.label}
                    name={column.key}
                    value={formValue[column.key]}
                    onChange={changeFormValue}
                    items={column.variants!.map((item) => ({ label: item, value: item }))}
                  />
                );
              } else if (column.type === 'snils') {
                return (
                  <FieldSnilsBlock
                    key={column.key}
                    label={column.label}
                    name={column.key}
                    value={formValue[column.key]}
                    onChange={changeFormValue}
                  />
                );
              }

              return (
                <FieldTextBlock
                  key={column.key}
                  label={column.label}
                  name={column.key}
                  value={formValue[column.key]}
                  onChange={changeFormValue}
                />
              );
            })}
            <FieldSelectBlock
              label="Пользователь"
              name="creator"
              value={formValue.creator}
              onChange={changeFormValue}
              items={creators.map((creator) => ({ label: creator.name, value: creator.id }))}
            />
            <ButtonSearchBlock
              onClick={onSubmitForm}
              disabled={!formDirty}
              contained
            />
            <ButtonClearBlock disabled={Object.keys(searchQuery).length === 0} onClick={() => onSearchHandler({})} />
          </form>
        </div>
      </div>
    </div>
  );
};

export { EntitySearchComponent };
